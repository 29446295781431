import { UseMapbox } from '@libero/mapbox/hooks/useMapbox';
import { ALL_CONTAINER_LAYERS, MARKED_POINT_LAYER } from '@libero/mapbox/types/layers';
import { throttle } from 'lodash';
import { MapLayerMouseEvent, MapLayerTouchEvent } from 'mapbox-gl';
import { Ref } from 'vue';
import { ref } from 'vue';

interface UseMarked {
  onAdd: (callback: OnContainerCallback) => void;
  onRemove: (callback: OnContainerCallback) => void;
  handleRemove: (id: string) => void;
}

type OnContainerCallback = (id: string) => void;

export const useMarked = (mapbox: UseMapbox, isEnabled: Ref<boolean>): UseMarked => {
  const addCallbacks = ref<OnContainerCallback[]>([]);
  const removeCallbacks = ref<OnContainerCallback[]>([]);

  const handleRemove = (id: string) => {
    const filter = mapbox.map.getFilter(MARKED_POINT_LAYER);
    if (!filter) return;

    filter[1] = ['in', 'id', ...filter[1].splice(2).filter((i: string) => i !== id)];
    mapbox.map.setFilter(MARKED_POINT_LAYER, filter);
  };

  const toggle = (id: string) => {
    const filter = mapbox.map.getFilter(MARKED_POINT_LAYER);
    if (!filter) return;

    const hasId = filter[1].includes(id);

    if (hasId) {
      filter[1] = ['in', 'id', ...filter[1].splice(2).filter((i: string) => i !== id)];
      removeCallbacks.value.forEach((callback) => callback(id));
    } else {
      filter[1] = ['in', 'id', ...filter[1].splice(2), id];
      addCallbacks.value.forEach((callback) => callback(id));
    }

    mapbox.map.setFilter(MARKED_POINT_LAYER, filter);
  };

  const handleClick = throttle((event: MapLayerTouchEvent | MapLayerMouseEvent) => {
    if (!isEnabled.value) return;
    if (!mapbox.map || !event.features?.length) return;

    const [feature] = event.features;

    if (feature.properties) {
      toggle(feature.properties.id);
    }
  }, 500);

  const onAdd = (callback: OnContainerCallback) => {
    addCallbacks.value.push(callback);
  };

  const onRemove = (callback: OnContainerCallback) => {
    removeCallbacks.value.push(callback);
  };

  mapbox.onStyleLoad(() => {
    mapbox.map.on('click', ALL_CONTAINER_LAYERS, handleClick);
    mapbox.map.on('touchend', ALL_CONTAINER_LAYERS, handleClick);
  });

  return {
    onAdd,
    onRemove,
    handleRemove,
  };
};
