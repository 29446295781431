import { UseGeoJsonSource } from '@libero/mapbox/hooks/sources/useGeoJsonSource';
import { UseMapbox } from '@libero/mapbox/hooks/useMapbox';
import { ACTIVE_LINE_LAYER, LINE_LAYER, NEW_LINE_LAYER } from '@libero/mapbox/types/layers';
import { DRAWING_SOURCE, GEOJSON_SOURCE } from '@libero/mapbox/types/sources';
import { Color } from '@libero/types/Color';

export const useLineLayer = (
  mapbox: UseMapbox,
  geoJsonSource: UseGeoJsonSource,
  color?: Color,
): void => {
  const registerLayers = () => {
    mapbox.map.addLayer({
      id: ACTIVE_LINE_LAYER,
      type: 'line',
      source: GEOJSON_SOURCE,
      paint: {
        'line-color': color || ['get', 'color'],
        'line-width': ['interpolate', ['linear'], ['zoom'], 11, 4, 25, 12],
        'line-opacity': 0.25,
      },
      filter: [
        'all',
        ['in', 'id', ''],
        ['any', ['==', '$type', 'LineString'], ['==', '$type', 'Polygon']],
      ],
    });

    mapbox.map.addLayer({
      id: LINE_LAYER,
      type: 'line',
      source: GEOJSON_SOURCE,
      paint: {
        'line-color': [
          'case',
          ['==', ['get', 'count'], 0],
          Color.gray800,
          color || ['get', 'color'],
        ],
        'line-width': [
          'interpolate',
          ['linear'],
          ['zoom'],
          11,
          ['case', ['==', ['get', 'count'], 0], 0.35, 1.25],
          25,
          ['case', ['==', ['get', 'count'], 0], 2.5, 9],
        ],
      },
      filter: ['any', ['==', '$type', 'LineString'], ['==', '$type', 'Polygon']],
    });

    if (mapbox.draw) {
      mapbox.map.addLayer({
        id: NEW_LINE_LAYER,
        type: 'line',
        source: DRAWING_SOURCE,
        paint: {
          'line-color': Color.primary600,
          'line-width': ['interpolate', ['linear'], ['zoom'], 9, 1, 25, 6],
        },
        filter: ['any', ['==', '$type', 'LineString'], ['==', '$type', 'Polygon']],
      });
    }
  };

  geoJsonSource.onLoad(registerLayers);
};
